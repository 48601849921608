/*body:not(.subsite) { //commentato perchè vogliono questi stili anche nei sottositi °_°*/
.public-ui {
  .megamenu {
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {
      .dropdown-menu[role='menu'] {
        background-color: $gdf-primary-dark;

        .link-list-wrapper {
          .link-list.bordered li h3 a span,
          .link-list.bordered li a span,
          ul li h3,
          ul li a i,
          ul li a span {
            color: #fff;
          }

          ul.link-list {
            width: 100%;
            padding: 0 0.5rem;
          }

          ul li a .icon {
            fill: #fff;
          }

          .link-list.bordered li {
            border-bottom-color: rgba(255, 255, 255, 0.7) !important;

            &:hover {
              background-color: $primary !important;
            }
          }
        }

        h2 {
          color: #fff;
          font-size: 1.5em;
        }

        .dropdownmenu-blocks-column {
          .block:first-of-type {
            h2 {
              margin-top: 0 !important;
            }
          }
        }
      }

      .dropdown.show > a.nav-link[aria-expanded='true'] {
        color: $tertiary;

        .megamenu-toggle-icon {
          fill: $tertiary;
        }

        &::after {
          border-bottom-color: transparent;
        }
      }

      .dropdown-menu.show[role='menu'] {
        border-top: 5px solid $tertiary;
        border-bottom: 6px solid $megamenu-border-green;
        border-radius: 0;
        box-shadow: inset 0px 0 0px -5px $megamenu-border-green,
          inset 0px 5px 0px 0px $megamenu-border-green, 0px 0 0px -5px $tertiary,
          inset 0px -5px 0px 0px $tertiary;
      }

      .megamenu-close-button .btn-link {
        color: #fff;
        fill: #fff;
      }
    }
  }

  .navbar.has-megamenu li.nav-item.active .dropdown a.nav-link {
    border-color: $tertiary;
  }

  .dropdownmenu-blocks-column {
    .card {
      .card-body {
        .category-top {
          flex-wrap: wrap;

          .data {
            flex: 1 1 100%;
            order: 0;
            text-align: right;

            &:before {
              content: none;
            }
          }

          .icon {
            order: 1;
          }

          .text {
            order: 2;
          }
        }
      }
    }
  }
}
/*}*/
