.it-footer {
  .it-footer-main {
    background-color: hsb($primary-h, $primary-s, 35);

    .it-brand-wrapper {
      display: flex;

      a {
        h2 {
          font-size: 1.25em;

          @media (min-width: #{map-get($grid-breakpoints, lg)}) {
            font-size: 1.8rem;
          }
        }

        h3 {
          color: $tertiary;
          font-size: 0.78em;
          font-weight: bold;
          letter-spacing: 0.15em;
          text-transform: uppercase;
        }

        .icon {
          height: 40px;
          width: 140px;
          margin-right: 0.5rem;
          margin-bottom: 0;

          @media (min-width: #{map-get($grid-breakpoints, lg)}) {
            display: flex;
            width: 206px;
            height: 85px;
            align-items: center;
            margin-right: 1rem;
          }

          img {
            width: auto;
            max-height: 100%;
          }
        }
      }
    }
  }

  .it-footer-small-prints {
    background-color: hsb($primary-h, $primary-s, 35);

    .container {
      border-top: 1px solid $white;
    }
  }
}
