@import 'design-comuni-plone-theme/../theme/_site-variables.scss';
@import 'variables';
@import 'design-comuni-plone-theme/../theme/site.scss';

.public-ui {
  @import 'custom/main';
  /*import here your site customization styles*/
  @import 'custom/bootstrap-italia/custom/pagination';
  @import 'custom/bootstrap-italia/custom/headerslim';
  @import 'custom/bootstrap-italia/custom/headercenter';
  @import 'custom/bootstrap-italia/custom/headernavbar';
  @import 'custom/bootstrap-italia/custom/navigation';
  @import 'custom/bootstrap-italia/custom/footer';
  @import 'custom/bootstrap-italia/custom/card';
  @import 'custom/bootstrap-italia/custom/chip';

  @import 'custom/ItaliaTheme/Blocks/cardWithImageAndInEvidence';
  @import 'custom/ItaliaTheme/Blocks/cardWithSlideUpTextTemplate';

  @import 'custom/ItaliaTheme/Blocks/simpleCardTemplate';
  @import 'custom/ItaliaTheme/Blocks/squaresImageTemplate';
  @import 'custom/ItaliaTheme/Blocks/contentInEvidenceTemplate';
  @import 'custom/ItaliaTheme/Views/common';
  @import 'custom/ItaliaTheme/Views/gallery';
  @import 'custom/ItaliaTheme/Views/newsItem';
  @import 'custom/site/Blocks/Listing/cardWithSideImage';
  @import 'custom/extras/search';
}

@import 'custom/ItaliaTheme/Addons/volto-gdpr-privacy';
@import 'custom/ItaliaTheme/Components/megamenu';
@import 'custom/ItaliaTheme/Components/parentSiteMenu';
@import 'custom/ItaliaTheme/Components/tertiaryMenu';
@import 'custom/ItaliaTheme/Components/logo';
@import 'custom/ItaliaTheme/Blocks/numbers';
@import 'custom/ItaliaTheme/Blocks/countdown';
@import 'custom/ItaliaTheme/Blocks/highlitedContent';
@import 'custom/ItaliaTheme/Blocks/argumentsInEvidence';
@import 'custom/ItaliaTheme/Blocks/sliderTemplate';
@import 'custom/ItaliaTheme/Blocks/gridGalleryTemplate';
@import 'custom/ItaliaTheme/Blocks/photogalleryTemplate';
@import 'custom/ItaliaTheme/Blocks/twitter';
@import 'custom/ItaliaTheme/Views/bando';
@import 'custom/ItaliaTheme/Subsites/customizeItaliaSubsites';
@import 'custom/site/Blocks/Listing/listing';
@import 'custom/site/Blocks/Listing/noCard';
@import 'custom/site/Blocks/Listing/collaborationTableTemplate';
@import 'custom/site/Blocks/Listing/dirigentiTableTemplate';
@import 'custom/site/Blocks/ricercaReparti';
@import 'custom/site/View/atleta';
@import 'custom/site/View/dirigente';
@import 'custom/site/View/reparto';
@import 'custom/site/cms';

// @import 'custom/addons/flipbook';

@import 'custom/ItaliaTheme/Print/all_pages';
@import 'custom/ItaliaTheme/Print/blocks';
