body.subsite {
  .parent-site-menu {
    align-self: stretch;

    li.nav-item {
      display: flex;
      align-items: center;
      border-right: 1px solid $gdf-primary;
    }
  }
}
