.it-carousel-wrapper.it-carousel-landscape-abstract-three-cols {
  .slider-container {
    .it-video-wrapper {
      .it-single-video-wrapper {
        .embedded-video > div {
          padding: 0;
        }
      }
    }
  }
}
