.grid-gallery-template {
  .grid-gallery-grid {
    width: 100%;

    .grid-gallery-item {
      a {
        &::before {
          background: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .skeleton-template {
    .grid-gallery-grid {
      .grid-gallery-item {
        a {
          &::before {
            background: rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}
