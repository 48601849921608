//header center
.it-header-center-wrapper {
  & > .container {
    width: 100%;
    max-width: 100%;
    padding-right: 6px;
    padding-left: 6px;
    margin-right: auto;
    margin-left: auto;
  }

  .it-header-center-content-wrapper {
    .it-brand-wrapper {
      @media (min-width: #{map-get($grid-breakpoints, xl)}) {
        max-width: 50%;
      }

      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        margin-left: 24px;
      }

      a {
        @media (min-width: #{map-get($grid-breakpoints, lg)}) {
          h2 {
            font-size: 3em;
          }
        }

        h3 {
          color: $tertiary;
          font-size: 0.78em;
          font-weight: bold;
          letter-spacing: 0.15em;
          text-transform: uppercase;

          @media screen and (min-width: #{map-get($grid-breakpoints, sm)}) and (max-width: #{map-get($grid-breakpoints, lg)}) {
            font-size: 0.6em;
          }
        }

        .icon {
          height: 112px;
          width: auto;
          max-width: 100%;
          margin: 0;

          @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            height: 50px;
          }
          img {
            height: 100%;
            width: auto;
          }
        }
      }
    }

    // social desktop
    .it-right-zone {
      .it-socials {
        display: none !important;
      }
    }

    .it-search-wrapper {
      @media (min-width: #{map-get($grid-breakpoints, sm) + 1}) {
        margin-right: 8px;
      }

      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        margin-right: 24px;
      }
    }
  }
}
