.card-slide-text-template {
  margin-top: 40px;

  .container {
    overflow: hidden; /*mostra il margin bottom del pulsante*/
  }

  .grid.mb-3.mt-5 {
    margin: 0 !important;
  }
}

.cardSlideUpTextTemplate {
  .full-width.py-5 {
    overflow: hidden;
    padding: 0 !important;
  }
}
