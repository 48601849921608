.block.ricercaReparti {
  .results-container {
    padding-bottom: 2rem;
    border-bottom: 1px solid #ccc;
    margin-bottom: 2rem;
  }

  .totals {
    font-size: 0.8rem;
    font-style: italic;
  }

  .bootstrap-select-wrapper,
  .filter-wrapper.select-filter {
    width: 10rem;

    .css-2b097c-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
    }

    .react-select__placeholder {
      color: #fff !important;
    }

    .react-select__single-value {
      color: #fff !important;

      &:hover,
      &:focus {
        color: #f3f3f3 !important;
      }
    }

    .react-select__menu {
      z-index: 2;
    }

    .react-select__indicators .clearButton svg,
    .react-select__indicator {
      color: #fff !important;
      fill: #fff;
    }

    .react-select__control {
      flex: 1;
      border: 0 !important;
      background: none;
      color: #fff;
    }
  }

  .filter-wrapper.text-filter {
    width: 15rem;
  }

  .filter-wrapper.select-filter,
  .filter-wrapper.text-filter {
    margin-right: 1rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .reparto-pin-popup {
    .title {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, sm)}) {
    .filter-wrapper {
      &.text-filter,
      &.select-filter {
        width: 100% !important;
        margin-right: 0;
        margin-left: 0;
      }

      &.select-filter {
        .bootstrap-select-wrapper {
          width: 100%;
        }
      }
    }
  }
}
