body.contenttype-atleta {
  .header-atleta-image {
    max-width: 250px;
    margin-top: 1rem;

    @media (min-width: #{map-get($grid-breakpoints, lg)}) {
      margin-top: 0;
      margin-left: auto;
    }
  }

  .readingtime-dates {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  #specialita {
    .card.card-teaser {
      width: auto;

      .card-title {
        display: flex;
        align-items: center;
        margin-bottom: 0 !important;
      }

      picture {
        width: 30px;
        padding: 0.2rem;
      }
    }
  }
}
