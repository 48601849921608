// Mobile
@media (max-width: #{map-get($grid-breakpoints, sm)}) {
  .pagination-wrapper {
    ul.pagination {
      li.page-item {
        &:first-child {
          margin-right: 0;
          margin-left: -0.5rem;

          button.page-link {
            padding-right: 0;
            padding-left: 0;
          }
        }

        &:last-child {
          margin-right: -0.5rem;

          button.page-link {
            padding-right: 0;
            padding-left: 0;
          }
        }

        button.page-link {
          min-width: 2rem;
          height: 2rem;
          padding: 0.5rem;
        }
      }
    }
  }
}
