.card {
  .card-body {
    .card-title {
      a {
        font-size: 1.35rem;

        &:hover {
          color: $link-hover-color;
        }
      }
    }

    .card-text {
      color: $site-card-p-color;
      font-size: $site-card-p-size;
      line-height: 1.333rem;
    }

    .card-signature {
      color: $site-card-p-color;
    }

    .category-top {
      color: $site-card-p-color;
      font-size: 0.9rem;

      .text {
        font-size: 0.9rem;
        font-weight: $semibold;
      }

      a.category {
        color: $site-card-p-color;
      }

      .data {
        display: block;
        flex: 1 1 100%;
        margin-top: 0.5rem;

        &:before {
          content: none;
        }
      }
    }
  }

  .categoryicon-top {
    flex-wrap: wrap;

    .text {
      color: $site-card-p-color;
    }

    .icon {
      fill: $site-card-p-color;
    }
  }

  //head tag
  .head-tags {
    .data {
      color: $site-card-p-color;
    }
  }

  // card footer
  .it-card-footer {
    .card-signature {
      color: $site-card-p-color;
    }
  }
}

//.etichetta
.etichetta {
  color: $site-card-p-color;

  .icon {
    fill: $site-card-p-color;
  }
}

// read more text
a.read-more {
  font-size: 1rem;

  .icon {
    fill: $secondary;
  }
}

// flag
.flag-icon {
  background: $secondary;
}
