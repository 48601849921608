.it-header-navbar-wrapper {
  & > .container {
    width: 100%;
    max-width: 100%;
    padding-right: 6px;
    padding-left: 6px;
    margin-right: auto;
    margin-left: auto;
  }
}
