@media screen and (max-width: #{map-get($grid-breakpoints, lg)}) {
  .navbar .navbar-collapsable.expanded {
    .it-brand-wrapper {
      padding: 1.5rem;
      background-color: $header-bg-color;
    }
  }
  &.subsite .navbar .navbar-collapsable.expanded {
    .it-brand-wrapper {
      .icon {
        width: 50px;
      }
    }
  }
}
