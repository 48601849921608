a {
  color: $site-link-color;

  &:active {
    color: $link-hover-color;
  }
}

.it-header-wrapper {
  border-bottom: 15px solid $megamenu-border-green;

  .it-nav-wrapper {
    border-bottom: 15px solid $tertiary;
  }
}

.link-list-wrapper.menu-link-list {
  h3::after {
    background-color: $secondary;
  }
}

@media (max-width: #{map-get($grid-breakpoints, md)}) {
  .ui.image img.full-width,
  picture.volto-image.responsive.full-width img,
  picture.volto-image.full-width img,
  picture.volto-image.responsive img.full-width {
    height: 200px;
  }
}
