$listing-card-light-blue: #edf1f6;
$listing-card-dark-grey: $gdf-primary;
$listing-card-light-grey: #a9abbc;

// mixin for different cards
@mixin elements-listing-colors(
  $bg-card,
  $card-title,
  $read-more,
  $tertiary-text
) {
  .card {
    background-color: $bg-card;
    box-shadow: 0 2px 10px 0 #00000020 !important;

    .card-title {
      a {
        color: $card-title;
        &:hover,
        &:active,
        &:focus {
          color: $card-title;
        }
      }
    }

    a.read-more {
      color: $read-more;

      .icon {
        color: $read-more;
        fill: $read-more;
      }
    }
  }

  .ribbon-card-template {
    .card {
      .flag-icon {
        &::after {
          border-bottom-color: $bg-card;
        }
      }
    }
  }

  .small-block-links {
    .center-image-card {
      justify-content: center;
      background-color: $bg-card;
    }
  }

  .complete-block-links-template {
    .card {
      a,
      .text-secondary {
        color: $tertiary-text !important;
      }
    }
  }
}

// Button widget sidebar
#field-bg_color,
#field-items_color {
  .color-list-widget {
    .ui.button {
      &.light-blue {
        background-color: $listing-card-light-blue;
      }

      &.default {
        background-color: #fff;
      }

      &.light {
        background-color: $light !important;
      }

      &.dark-grey {
        background-color: $listing-card-dark-grey;

        &.active {
          border-color: $tertiary;
        }
      }

      &.light-grey {
        background-color: $listing-card-light-grey;
      }
    }
  }
}

// Listing variations
.block.listing {
  &,
  .public-ui {
    .items-color-light-blue {
      @include elements-listing-colors(
        $listing-card-light-blue,
        #000,
        $secondary,
        #000
      );
    }

    .items-color-light-grey {
      @include elements-listing-colors(
        $listing-card-light-grey,
        #000,
        #000,
        #000
      );

      .card {
        .chip.chip-simple.chip-primary {
          border-color: #000;

          &:hover,
          &:focus {
            background-color: #babccf;
          }

          .chip-label {
            color: #000;
          }
        }

        .icon-argument-container .icon,
        .icon-argument-container svg {
          color: #000;
          fill: #000;
        }
      }
    }

    .items-color-dark-grey {
      @include elements-listing-colors(
        $listing-card-dark-grey,
        $tertiary,
        #fff,
        #fff
      );

      .card {
        .chip.chip-simple.chip-primary {
          border-color: #fff;

          &:hover,
          &:focus {
            background-color: #343742;
          }

          .chip-label {
            color: #fff;
          }
        }

        .card-body .category-top,
        .card-body .card-text,
        .card-body .bando-description,
        .card-body .bando-dati,
        .categoryicon-top .text,
        .etichetta,
        .dates {
          color: #fff;
        }

        &.card-teaser .categoryicon-top .icon,
        .categoryicon-top .icon,
        .icon-argument-container .icon,
        .icon-argument-container svg,
        .etichetta .icon {
          color: #fff;
          fill: #fff;
        }
      }

      .in-evidence .listing-item .card-title a:hover,
      .in-evidence .listing-item .card-title a:focus {
        color: $tertiary;
      }

      .ribbon-card-template {
        .card {
          .flag-icon {
            background: #f4f4f4;
          }
        }
      }
    }
  }
}

// Background block variations
.block.listing {
  &,
  .public-ui {
    .bg-light {
      &.bg-light-grey {
        background-color: $listing-card-light-grey !important;
      }

      &.bg-dark-grey {
        background-color: $listing-card-dark-grey !important;

        h2 {
          color: $tertiary;
        }
      }
    }
  }
}
