.contentInEvidenceTemplate {
  .categoryicon-top .icon {
    width: 1rem;
    height: 1rem;
  }

  .card .card-body {
    h2.card-title {
      a {
        font-size: 2.2rem;
        line-height: 2.4rem;
      }
      margin-bottom: 0.8em !important;
    }
  }

  .chip {
    margin-right: 0.5rem;
  }
}
