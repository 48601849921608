@media print {
  // image block
  .block.image {
    max-width: 100%;

    &.align.full {
      picture.volto-image img {
        width: 100% !important;
        min-width: 100%;
        max-width: 100% !important;
        height: auto;
      }
    }

    picture.volto-image {
      position: relative;
      text-align: center;

      img {
        width: auto;
        max-width: 100% !important;
        height: 400px;
        max-height: 400px;
      }
    }
  }

  // countdown block
  .block.count_down {
    .text {
      max-width: 65%;
    }

    .countdown {
      max-width: 35%;
    }
  }

  // hero
  .block.hero {
    background-color: #edf1f2;
  }

  // twitter block
  .block.twitter_posts {
    & > .row-full-width.row {
      max-width: 100%;
      margin: 0 !important;
    }

    .slick-slider .slick-list,
    .public-ui .slick-slider .slick-list {
      display: flex;
      flex-direction: column;

      .slick-track {
        display: flex;
        width: 100% !important;
        flex-wrap: wrap;
        transform: none !important;
      }

      .slick-slide {
        display: none !important;
        max-width: 50%;

        &.slick-active,
        &.slick-active + .slick-slide {
          display: flex !important;
        }
      }
    }
  }

  // listing Slider
  .block.listing {
    .sliderTemplate .slider-container {
      .slick-slide .slide-wrapper figure.img-wrapper {
        min-height: 400px;
      }

      &.full-width .slick-slide .slide-wrapper figure.img-wrapper img {
        max-width: 100%;
        min-height: auto;
      }
    }
  }
}
