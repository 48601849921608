#search-modal {
  .modal-header {
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .modal-title {
      .modal-title-centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        color: $gdf-primary;
        font-size: 2.2rem;

        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          font-size: 1.3rem;
        }

        &::before {
          display: flex;
          width: 100%;
          height: 100px;
          align-items: center;
          margin: 20px auto 35px;
          background-image: url('../../images/logo_search.png?height=100');
          background-position: center;
          background-repeat: no-repeat;

          content: ' ';
          text-transform: none;
          background-size: 190px;

          @media (max-width: #{map-get($grid-breakpoints, md)}) {
            font-size: 1.5rem;
            line-height: 1.8rem;
            background-size: 140px;
          }

          @media (max-width: #{map-get($grid-breakpoints, sm)}) {
            height: 70px;
            padding-left: 65px;
            margin-right: 1rem;
            margin-left: 0;
          }
        }
      }
    }
  }

  // back button
  .btn.btn-link {
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      padding-right: 12px;
      padding-left: 0;
    }
  }

  // search button (hidden)
  .btn.btn-outline-primary {
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      padding-right: 12px;
      padding-left: 12px;
      display: none;
    }
  }

  .modal-body {
    padding-top: 0;

    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .search-filters:first-child {
      margin-top: 10px;
    }
  }
}
