.collaboration-table-template {
  table,
  .table {
    tr th,
    tr td {
      min-width: 125px;
      font-size: 0.8rem;
    }

    th,
    thead th {
      vertical-align: top;
    }

    th.allegati-col {
      min-width: 130px;
    }

    ul {
      padding-left: 1.3rem;
    }
  }
}
