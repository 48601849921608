//site
$primary-h: 234 !default;
$primary-s: 21 !default;
$primary-b: 40 !default;

$gdf-primary: #505265; //è l'equivalente del hsb primary
$gdf-primary-dark: #474859; //colore header
$secondary: #437390;
$tertiary: #f9c800;
$tertiary-text: #000;

$megamenu-border-green: #066731;

$header-bg-color: $gdf-primary-dark;
$header-center-bg-color: $gdf-primary-dark;
$body-color: #303031;

$headings-color: #444444;
$site-card-p-color: $body-color;
$site-card-p-size: 1rem;

$semibold: 600;

$site-link-color: lighten($secondary, 3);
$link-hover-color: $secondary;

$argument-icon-color: $secondary;
$argument-icon-bg: rgba($secondary, 0.1);
