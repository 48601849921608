.simple-card-default {
  .card.card-teaser {
    .categoryicon-top {
      .icon {
        color: $site-card-p-color;
        fill: $site-card-p-color;
      }
    }
  }
}
