.block.listing .no-card-template {
  color: #fff;

  .container {
    padding-top: 5rem;
    padding-bottom: 5rem;

    h2,
    .description {
      color: #fff;
      text-align: center;
    }
  }

  .card {
    background: transparent !important;
    box-shadow: none !important;

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      margin-bottom: 2rem;
    }

    &:after {
      content: none;
    }

    .card-body,
    .card-title,
    .card-title a,
    .card-text,
    .category-top,
    .categoryicon-top .icon,
    .categoryicon-top .text {
      color: #fff;
    }

    .category-top {
      font-size: 0.8rem;

      .text {
        font-size: 0.8rem;
        font-weight: 500 !important;
      }

      .icon {
        max-width: 1.5rem;
        max-height: 1.5rem;

        fill: #fff;
      }
    }

    .card-title {
      margin-bottom: 0 !important;
    }

    .card-text {
      font-family: $font-family-sans-serif;
    }
  }
}

.block.listing {
  &,
  .public-ui {
    .items-color-light-blue .no-card-template,
    .items-color-light-grey .no-card-template,
    .items-color-dark-grey .no-card-template {
      .card {
        box-shadow: none !important;

        &-title a {
          color: #fff;
        }
      }
    }
  }
}
