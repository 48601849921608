@mixin light-theme-gdf() {
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    .public-ui {
      .megamenu {
        .dropdown.show > a.nav-link[aria-expanded='true'] {
          .megamenu-toggle-icon {
            fill: #000;
          }
        }
      }
    }
  }
}
