.squares-image-template {
  .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .box {
    height: 255px;

    .title {
      font-size: 1.55rem;
      line-height: 1.65rem;
    }

    &:before {
      background-color: rgba(63, 65, 66, 0.6);
    }
  }

  //xl monitor
  @media (max-width: #{map-get($grid-breakpoints, xl)}) {
    .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  //large monitor
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  // smartphone

  @media (max-width: #{map-get($grid-breakpoints, sm)}) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
}
