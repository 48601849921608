//custom VenuesSmall per "Reparti correlati"
#reparti-correlati {
  .card-wrapper.card-teaser-wrapper {
    justify-content: center;

    @media (min-width: #{map-get($grid-breakpoints, sm)}) {
      .card-teaser {
        flex: 0 0 calc(33% - 0.7rem * 2);
        margin: 0 0.7rem;
      }
    }
  }
}
