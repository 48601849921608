*,
.public-ui {
  .block.highlitedContent {
    .categoryicon-top .icon {
      width: 1rem;
      height: 1rem;
    }

    .card {
      .card-body {
        h2.card-title {
          a {
            font-size: 2.2rem;
            line-height: 2.4rem;
          }
          margin-bottom: 0.8em !important;
        }

        .card-text {
          margin-bottom: 2rem;
          font-family: $font-family-sans-serif;
        }

        .chip {
          margin-right: 0.5rem;
        }
      }
    }

    .bg-primary,
    .bg-secondary {
      .card {
        .card-body {
          a.read-more {
            /* TODO: rivedere questo senza usare @extend !!!
             * @extend .btn;
	     */
            @include button-variant($tertiary, $tertiary);
            color: $tertiary-text;

            svg.icon {
              color: $tertiary-text;
              fill: $tertiary-text;
            }
          }
        }
      }
    }
  }
}
