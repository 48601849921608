.block.count_down {
  .public-ui .block-content {
    padding: 0;

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      .order-last {
        margin-top: 0.5rem;
      }
    }
  }

  .public-ui .background-image {
    background-color: #b2b2b2;

    &:after {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .count-down-timer {
    justify-content: flex-start;

    .interval {
      .number {
        padding: 0.7rem;
        font-size: 1.8rem;
      }

      .label {
        font-size: 1rem;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    .countdown.col-lg-8 {
      max-width: 35%;
      flex: 0 0 35%;
    }
  }

  @media screen and (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)}) {
    .countdown.col-lg-8 {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }

  .text {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;

    .text,
    .text .DraftEditor-root {
      width: 100%;
    }

    @media (min-width: #{map-get($grid-breakpoints, lg)}) {
      &.col-lg-4 {
        max-width: 65%;
        flex: 0 0 65%;
      }
    }

    @media screen and (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)}) {
      &.col-lg-4 {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
  }
}
