.card-with-side-image-template {
  padding-top: 3em;
  padding-bottom: 3em;

  h2,
  .description {
    text-align: center;
  }

  .items {
    margin: 2em 0 3em 0;

    .col-item {
      margin-bottom: 1em;
    }

    .card {
      flex-direction: row;
      align-items: stretch;

      &:after {
        content: none;
      }

      a {
        text-decoration: none;

        &:hover,
        &:active {
          text-decoration: underline;
        }
      }

      .card-body {
        width: 60%;

        .card-title {
          margin-bottom: 0 !important;
        }

        .card-text {
          font-family: $font-family-sans-serif;
        }
      }

      .image-container {
        position: relative;
        overflow: hidden;
        width: 40%;

        img {
          position: absolute;
          left: 50%;
          width: auto;
          min-width: 100%;
          height: 100%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
