.block.listing .card-with-image-template,
.block.listing .in-evidence {
  margin-top: 40px;

  .listing-item {
    .card-title {
      a {
        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }

  .container {
    overflow: hidden; /*mostra il margin bottom del pulsante*/
  }

  h2.mb-4.mt-5 {
    margin-top: 0 !important;
  }
}

.block.listing.cardWithImageTemplate {
  margin-bottom: 0 !important;

  .full-width.py-5 {
    overflow: hidden;
    padding: 0 !important;
  }
}

.in-evidence {
  .card-wrapper:first-child {
    grid-row: 1 / 5;
  }

  .card-wrapper:nth-child(2) {
    grid-row: 1 / 3;
  }

  .card-wrapper:nth-child(3) {
    grid-row: 3 / 5;
  }

  .card-wrapper:nth-child(5) {
    grid-row: 2 / 4;
  }
}
