//backgroud headerslim
.it-header-slim-wrapper {
  background: $gdf-primary;
}

//headerslim logo - main Site
.it-header-slim-wrapper {
  .it-header-slim-wrapper-content {
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      flex-wrap: nowrap;
    }

    a.navbar-brand {
      @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        white-space: unset;
      }

      &::before {
        display: inline-block;

        width: 24px;
        height: 23px;

        margin-right: 0.7rem;

        background-image: url('../../../images/logo_mef.png?width=24');
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 100%;

        content: '';
        vertical-align: text-bottom;

        @media (max-width: #{map-get($grid-breakpoints, sm)}) {
          display: none;
        }
      }
    }
  }
}

//headerslim logo - Subsite
&.subsite {
  .it-header-slim-wrapper {
    .it-header-slim-wrapper-content {
      a.navbar-brand {
        &::before {
          width: 17px;
          background-image: url('../../../images/stemma-nobg.png?width=17');
          content: '';
          border-radius: unset;
        }
      }
    }
  }
}

.it-header-slim-wrapper {
  & > .container {
    width: 100%;
    max-width: 100%;
    padding-right: 6px;
    padding-left: 6px;
    margin-right: auto;
    margin-left: auto;
  }

  .it-header-slim-wrapper-content {
    a.navbar-brand {
      margin-right: 0;

      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        margin-left: 24px;
      }

      @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        font-size: 0.7em;
      }
    }

    .it-header-slim-right-zone.header-slim-right-zone {
      // social network
      .it-socials {
        height: 100%;
        align-items: center;
        padding-right: 0.45rem;
        border-right: 1px solid $gdf-primary-dark;

        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          display: none !important;
        }

        & > span {
          display: none;
        }

        ul {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.1rem;

          li {
            padding: 0 0.5rem;
            list-style: none;
          }
        }
      }
    }

    .btn-primary {
      background-color: $gdf-primary-dark;
    }
  }
}
