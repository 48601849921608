body.contenttype-bando {
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    .header-bando-more-info {
      margin-left: 1rem;
    }

    .bando-view .bando_state {
      width: 100%;
      max-width: 19em;
    }
  }
}
