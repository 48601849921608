.navbar-collapsable {
  .it-brand-wrapper a .icon {
    margin-bottom: 0;
    height: 52px;
    width: auto;
    img {
      max-height: 100%;
      width: auto;
      background-color: $header-bg-color; //serve solo quando c'è l'immagine di natale
    }
  }
}

@media (max-width: 991px) {
  .navbar-collapsable {
    .it-brand-wrapper {
      background-color: $header-bg-color;
    }
  }
}
