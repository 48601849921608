.tertiary-menu {
  li.nav-item {
    border-right: 1px solid $gdf-primary-dark;

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      a {
        color: $gdf-primary-dark;
      }
    }
  }
}
