.photogallery {
  .slick-dots {
    ul {
      display: none;
    }

    .play-pause-wrapper {
      button {
        padding: 1px 10px 3px;
        margin-left: 0.5rem;
        background-color: $primary;
        border-radius: 5px;

        svg {
          fill: white;
        }
      }
    }
  }
}
