@media print {
  .public-ui {
    min-width: 100%;

    // header
    .it-header-center-wrapper .it-header-center-content-wrapper {
      margin-bottom: 1rem;

      .it-brand-wrapper a {
        .it-brand-text {
          h2.no_toc {
            margin-bottom: 0.5rem;
            font-size: 24px !important;
          }

          h3.no_toc {
            display: block !important;
            font-size: 14px !important;
          }
        }

        .icon {
          width: 65px;
          height: 65px;
          margin-right: 1rem;
        }
      }
    }

    .it-right-zone {
      display: block !important;
    }
  }

  .it-header-wrapper {
    width: 100%;
    margin-bottom: 2rem;
  }
}
