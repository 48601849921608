//serve per fare in modo che il titolo nelle pagine di intestazione, occupi tutta la larghezza disponibile, come hanno chiesto
@media (min-width: 992px) {
  .page-header {
    .page-header-right {
      width: auto;
      max-width: unset;
      flex: 0 0 100%;
      order: 0;
      margin-left: auto;
      text-align: right;
    }

    .page-header-left {
      width: 100%;
      max-width: 100%;
      flex: 1 0 80%;
      order: 1;
    }

    .page-header-image {
      order: 2;

      figure {
        margin-top: 1rem;
      }
    }
  }
}

//serve nelle view dei ct (in particolare nel bando). Hanno chiesto che le card degli uffici siano sempre mostrate al 100%
#ufficio_responsabile,
#area_responsabile {
  .card-wrapper.card-teaser-wrapper .card-teaser {
    flex: 0 0 100%;
  }
}

//unificato link attachments nella card, view Bando, NewsItem, ecc
.it-page-sections-container {
  .card-wrapper.card-teaser-wrapper {
    svg.icon {
      color: $site-link-color;
      fill: $site-link-color;
    }

    & > .card-teaser.attachment .card-title a {
      color: $site-link-color;
      font-weight: 300;

      &:hover {
        color: $link-hover-color;
      }
    }
  }

  //aggiunto stile per rendere le immagini della galleria interna alle view tutte della stessa dimensione
  .it-single-slide-wrapper {
    picture.volto-image {
      display: block;
      width: 100%;
      height: 180px;
    }

    .volto-image.responsive img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
