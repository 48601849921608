.slick-slide .slide-wrapper figure.img-wrapper .volto-image.responsive img,
.block.listing {
  .sliderTemplate .slider-container {
    .slick-slide .slide-wrapper {
      &.slide-title {
        padding: 0.6rem 1.2rem 0.8rem;
        background-color: rgba(63, 65, 66, 0.6);
        line-height: 1.5;
      }

      figure.img-wrapper {
        display: flex;
        align-items: center;
      }
    }

    &.full-width {
      .slick-track {
        min-height: 480px;
      }

      .slick-slide .slide-wrapper figure.img-wrapper {
        height: 480px;

        img {
          min-height: 480px;
        }

        .volto-image.responsive img {
          height: 480px;
        }
      }
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    .sliderTemplate .slider-container {
      &.full-width {
        .slick-track {
          min-height: 200px;
        }

        .slick-slide .slide-wrapper figure.img-wrapper {
          height: 200px;

          img {
            min-height: 200px;
          }
          .volto-image.responsive img.listing-image {
            height: auto;
          }
        }
      }
    }
  }
}
